// Entry point for the build script in your package.json
import "@rails/ujs";
require("@rails/activestorage").start();
require("./channels");

const images = require.context("./images", true);
const imagePath = (name) => images(name, true);

import * as Sentry from "@sentry/browser";
Sentry.init({ dsn: "https://5bacb386806744e6bcf09d865142e9f3@sentry.io/5184546" });

import "../assets/stylesheets/application.postcss.css";

import "react-toastify/dist/ReactToastify.css";
import "ldbutton/dist/ldbtn.css";
import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
window.Alpine = Alpine;
